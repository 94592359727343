<template>
  <div>
    <div class="z-0">
      <header>
        <ConnectWallet />
      </header>

      <div v-if="!getMenu">
        <div class="mx-auto sm:max-w-xl md:max-w-full xl:max-w-screen-xl p-2">
          <div class="mt-10">
            <h3 class="lg:text-3xl sm:text-2xl font-sans font-bold">OEM</h3>

            <div
              class="grid gap-4 grid-cols-2 lg:grid-cols-4 md:grid-cols-2 mt-10"
            >
              <div
                v-for="caseEx in caseExample"
                :key="caseEx"
                class="cursor-pointer hover:bg-gray-200 p-3 rounded"
              >
                <a :href="'/products_detail/' + caseEx._id">
                  <img class="w-full" :src="caseEx.img" />
                  <div class="text-center">
                    <b class="text-xl font-sans font-extrabold mt-5">
                      THB {{ caseEx.price }}
                    </b>
                  </div>
                </a>
              </div>
            </div>
          </div>

          <div class="mt-24">
            <div
              class="grid gap-4 grid-cols-2 lg:grid-cols-4 md:grid-cols-2 mt-10"
            >
              <div
                v-for="caseEx in t_shirt"
                :key="caseEx"
                class="cursor-pointer hover:bg-gray-200 p-3 rounded"
              >
                <a :href="'/products_detail/' + caseEx._id">
                  <img class="w-full" :src="caseEx.img" />
                  <div class="text-center">
                    <b class="text-xl font-sans font-extrabold mt-5">
                      THB {{ caseEx.price }}
                    </b>
                  </div>
                </a>
              </div>
            </div>
          </div>

          <div class="mt-24">
            <div
              class="grid gap-4 grid-cols-2 lg:grid-cols-4 md:grid-cols-2 mt-10"
            >
              <div
                v-for="caseEx in case_phone"
                :key="caseEx"
                class="cursor-pointer hover:bg-gray-200 p-3 rounded"
              >
                <a :href="'/products_detail/' + caseEx._id">
                  <img class="w-full" :src="caseEx.img" />
                  <div class="text-center">
                    <b class="text-xl font-sans font-extrabold mt-5">
                      THB {{ caseEx.price }}
                    </b>
                  </div>
                </a>
              </div>
            </div>
          </div>

          <div class="mt-24">
            <div
              class="grid gap-4 grid-cols-2 lg:grid-cols-4 md:grid-cols-2 mt-10"
            >
              <div
                v-for="caseEx in caseExample"
                :key="caseEx"
                class="cursor-pointer hover:bg-gray-200 p-3 rounded"
              >
                <a :href="'/products_detail/' + caseEx._id">
                  <img class="w-full" :src="caseEx.img" />
                  <div class="text-center">
                    <b class="text-xl font-sans font-extrabold mt-5">
                      THB {{ caseEx.price }}
                    </b>
                  </div>
                </a>
              </div>
            </div>
          </div>

          <div class="mt-24">
            <div
              class="grid gap-4 grid-cols-2 lg:grid-cols-4 md:grid-cols-2 mt-10"
            >
              <div
                v-for="caseEx in t_shirt"
                :key="caseEx"
                class="cursor-pointer hover:bg-gray-200 p-3 rounded"
              >
                <a :href="'/products_detail/' + caseEx._id">
                  <img class="w-full" :src="caseEx.img" />
                  <div class="text-center">
                    <b class="text-xl font-sans font-extrabold mt-5">
                      THB {{ caseEx.price }}
                    </b>
                  </div>
                </a>
              </div>
            </div>
          </div>

          <div class="mt-24">
            <div
              class="grid gap-4 grid-cols-2 lg:grid-cols-4 md:grid-cols-2 mt-10"
            >
              <div
                v-for="caseEx in case_phone"
                :key="caseEx"
                class="cursor-pointer hover:bg-gray-200 p-3 rounded"
              >
                <a :href="'/products_detail/' + caseEx._id">
                  <img class="w-full" :src="caseEx.img" />
                  <div class="text-center">
                    <b class="text-xl font-sans font-extrabold mt-5">
                      THB {{ caseEx.price }}
                    </b>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>

        <StickFooter class="mt-44" />
      </div>
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";
import StickFooter from "@/components/Footer.vue";

import { mapGetters, mapActions } from "vuex";

import glassExample from "../../glassExample";
import umbrellaExample from "../../umbrellaExample";

import { Carousel, Navigation, Pagination, Slide } from "vue3-carousel";

// import { products, main_product, products_2 } from "../../products";

export default {
  data() {
    return {
      caseExample: [
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1130780577365229630/Drinkware1.jpg",
          name: "iPhone 14 Pro Bounce Extreme Case MagSafe Compatible Waterproof",
          color: "Black",
          price: "599",
          _id: "1",
        },
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1130780577650462720/Drinkware2.jpg",
          name: "iPhone 14 Pro Bounce Extreme Case MagSafe Compatible Waterproof",
          color: "Black",
          price: "1,900",
          _id: "2",
        },
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1130780577902108772/Drinkware3.jpg",
          name: "iPhone 14 Pro Bounce Extreme Case MagSafe Compatible Waterproof",
          color: "Black",
          price: "1,900",
          _id: "3",
        },
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1130780578153758782/Drinkware4.jpg",
          name: "iPhone 14 Pro Bounce Extreme Case MagSafe Compatible Waterproof",
          color: "Black",
          price: "1,900",
          _id: "4",
        },
      ],
      t_shirt: [
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1130781126982635581/t-shirt1.jpg",
          name: "iPhone 14 Pro Bounce Extreme Case MagSafe Compatible Waterproof",
          color: "Black",
          price: "599",
          _id: "1",
        },
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1130781127263670364/t-shirt2.jpg",
          name: "iPhone 14 Pro Bounce Extreme Case MagSafe Compatible Waterproof",
          color: "Black",
          price: "1,900",
          _id: "2",
        },
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1130781127544680508/t-shirt3.jpg",
          name: "iPhone 14 Pro Bounce Extreme Case MagSafe Compatible Waterproof",
          color: "Black",
          price: "1,900",
          _id: "3",
        },
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1130781127825690684/t-shirt4.jpg",
          name: "iPhone 14 Pro Bounce Extreme Case MagSafe Compatible Waterproof",
          color: "Black",
          price: "1,900",
          _id: "4",
        },
      ],
      case_phone: [
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1130780680977121360/Phonecase1.jpg",
          name: "iPhone 14 Pro Bounce Extreme Case MagSafe Compatible Waterproof",
          color: "Black",
          price: "599",
          _id: "1",
        },
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1130780681451085955/Phonecase2.jpg",
          name: "iPhone 14 Pro Bounce Extreme Case MagSafe Compatible Waterproof",
          color: "Black",
          price: "1,900",
          _id: "2",
        },
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1130780681866334228/Phonecase3.jpg",
          name: "iPhone 14 Pro Bounce Extreme Case MagSafe Compatible Waterproof",
          color: "Black",
          price: "1,900",
          _id: "3",
        },
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1130780682168311929/Phonecase4.jpg",
          name: "iPhone 14 Pro Bounce Extreme Case MagSafe Compatible Waterproof",
          color: "Black",
          price: "1,900",
          _id: "4",
        },
      ],
    };
  },
  components: {
    ConnectWallet,
    StickFooter,
    Carousel,
    Navigation,
    Pagination,
    Slide,
  },
  computed: mapGetters(["getMenu"]),

  methods: {},
  created() {},
};
</script>
